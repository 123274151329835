import React, { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    Filter,
    List,
    EditButton,
    EmailField,
    TextField,
    SearchInput,
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import CustomerLinkField from './CustomerLinkField';
import NotificationBulkActionButton from './NotificationBulkActionButton';

const useStyles = makeStyles(
    {
        nb_commands: { color: 'purple' },
        balance: { color: 'brown' },
        search: {
            marginTop: 32,
        },
    },
    { name: 'RaSearchInput' }
);

const ClientsBulkActionButtons = props => (
    <Fragment>
        <NotificationBulkActionButton label="Reset Views" {...props} />
    </Fragment>
);

const ClientFilter = (props: any) => {
    const classes = useStyles();

    return (
        <Filter {...props}>
            <SearchInput source="q" resettable alwaysOn />
        </Filter>
    );
};

const ClientList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<ClientFilter />}
            // undoable={false}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={<ClientsBulkActionButtons />}
            // exporter={exporter}
            exporter={false}
        >
            <Datagrid optimized rowClick="edit">
                <CustomerLinkField />
                <TextField
                    source="name.first"
                    label="resources.clients.fields.first_name"
                />
                <TextField
                    source="name.last"
                    label="resources.clients.fields.last_name"
                />
                {/* <TextField 
                    source="country_code" 
                    label="resources.clients.fields.country_code" />
                     */}
                <EmailField
                    source="email"
                    label="resources.clients.fields.email"
                />
                <TextField
                    source="phone"
                    label="resources.clients.fields.phone"
                />
                {/* <TextField source="city" 
                    label="resources.clients.fields.city"/> */}
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.clients.fields.created_at"
                />{' '}
                />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default ClientList;
