import React from 'react';
import {
    NumberField,
    TextField,
    DateField,
    useTranslate,
    useGetList,
    linkToRecord,
} from 'react-admin';
import PropTypes from 'prop-types';
import {
    Tooltip,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Avatar,
    IconButton,
    Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ContentCreate from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';

import PostThumbnailField from './PostThumbnailField';

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    card: {
        marginBottom:20,
    },
}));

const Aside = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
          
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};


export default Aside;
