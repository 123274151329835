// in src/NotFound.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    icon: {
        color:theme.palette.primary.main,
    },
}));

export default () => {
    const classes = useStyles();
    return(
    <Card>
        <Title title="Module en cours de développement" />
        <CardContent>
            <center>
                <h1 className={classes.icon}>
                    Module en cours de développement
                </h1>
            </center>
        </CardContent>
    </Card>
)
    };
