import React from 'react';
import { InputProps } from 'ra-core';
import {
    Datagrid,
    TextField,
    Filter,
    List,
    SearchInput,
    EditButton,
    SelectField,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import { useMediaQuery, makeStyles, Theme } from '@material-ui/core';

import ColoredNumberField from './ColoredNumberField';
import PostThumbnailField from './PostThumbnailField';

const categories = [
    { id: 'hi_tech', name: 'Hi Tech' },
    { id: 'mode', name: 'Mode' },
    { id: 'electromenager', name: 'Electroménager' },
    { id: 'bijoux', name: 'Bijoux' },
    { id: 'divers', name: 'Divers' },
];

const PostFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput
            label="Client"
            source="user"
            reference="users"
            allowEmpty
            alwaysOn
        >
            {/* <SelectInput optionText="name.full" /> */}
            <AutocompleteInput optionText="name.full" />
        </ReferenceInput>
        <SelectInput
            label="Categorie"
            source="category"
            choices={categories}
            alwaysOn
        />
    </Filter>
);

const useStyles = makeStyles({});

interface Props extends Omit<InputProps, 'source'> {
    source?: string;
}

const PostList = (props: any) => {
    const classes = useStyles();
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    return (
        <List
            {...props}
            filters={<PostFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
            exporter={false}
        >
            <Datagrid rowClick="edit">
                <PostThumbnailField
                    index={0}
                    label="resources.posts.fields.image1"
                />
                <PostThumbnailField
                    index={1}
                    label="resources.posts.fields.image2"
                />
                <PostThumbnailField
                    index={2}
                    label="resources.posts.fields.image3"
                />
                <TextField
                    source="user.name.full"
                    label="resources.posts.fields.user"
                />
                <TextField source="name" label="resources.posts.fields.name" />
                {/* <TextField
                    source="category"
                    label="resources.posts.fields.category"
                /> */}
                <SelectField
                    source="category"
                    choices={categories}
                    translateChoice={false}
                    label="resources.posts.fields.category"
                />

                <ColoredNumberField
                    source="price"
                    options={{}}
                    label="resources.posts.fields.price"
                />
                {/* 
                    <ColoredNumberField source="ordersCount" options={{}}  label="resources.posts.fields.ordres"/> */}

                <EditButton />
            </Datagrid>
        </List>
    );
};

export default PostList;
