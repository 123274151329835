import { AuthProvider } from 'ra-core';

const authProvider: AuthProvider = {
    login: (params) => {
        const { email, password } = params;
        const request = new Request(
          process.env.REACT_APP_ADMIN_REST_API_URL + "/auth/signin",
          {
            method: "POST",
            body: JSON.stringify({ email, password }),
            headers: new Headers({ "Content-Type": "application/json" })
          }
        );
        return fetch(request, { credentials: "include" })
          .then(response => {
            return response.json();
          })
          .then((user) => {
            if (user) {
              localStorage.setItem("user", user);
              localStorage.setItem("role", user.roles[0]);
              return Promise.resolve();
            } else {
              throw new Error("Authentication Failed");
            }
          })
          .catch(err => {
            console.log(err)
            return Promise.reject(err);
          });

    },
    logout: (params) => {
        //Clear localstorage
        localStorage.removeItem("user");
        localStorage.removeItem("role");
        //Logout from server
        const logout_request = new Request(
          process.env.REACT_APP_ADMIN_REST_API_URL + "/auth/signout",
          {
            method: "GET",
            headers: new Headers({ "Content-Type": "application/json" })
          }
        );

        console.log("Start logout");
        console.log(process.env.REACT_APP_ADMIN_REST_API_URL + "/auth/signout")
        return fetch(logout_request)
          .then(response => {
              console.log("logout response");
              console.log(response);
            return Promise.resolve();
          })
          .catch(err => {
            console.log("logout err");
            console.log(err);
            return Promise.resolve();
          });
    },
    checkError: (params) => { const status = params.status;
        if (status === 500 || status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("role");
          return Promise.reject();
        }
        return Promise.resolve();},
    checkAuth: (params) =>
       {
        return localStorage.getItem("user") && localStorage.getItem("role")
        ? Promise.resolve()
        : Promise.reject();
       },
    getPermissions: (params) => {
        const role = localStorage.getItem("role");
        return role ? Promise.resolve(role) : Promise.reject();

    },
};

export default authProvider;
