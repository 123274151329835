import PostIcon from './PostIcon';

import PostList from './PostList';
import PostCreate from './PostCreate';
import PostEdit from './PostEdit';

export default {
    list: PostList,
    // create: PostCreate,
    edit: PostEdit,
    icon: PostIcon,
};
