import React, { FC } from 'react';
import {
    DateInput,
    DateField,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    NumberInput,
    required,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { validatePasswords } from './ClientCreate';
import { Customer, FieldProps } from '../types';

const ClientEdit = (props: any) => {
    return (
        <Edit
            title={<ClientTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <ClientForm />
        </Edit>
    );
};

const ClientTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const ClientForm = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.customers.fieldGroups.identity'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name.first"
                                                resource="users"
                                                label="resources.customers.fields.firstName"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name.last"
                                                resource="users"
                                                label="resources.customers.fields.lastName"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Separator/>
                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="users"
                                        label="resources.customers.fields.email"
                                        validation={{ email: true }}
                                        disabled
                                        fullWidth
                                    />
                                     <Separator/>
                                     <Separator/>
                                    <TextInput
                                        type="phone"
                                        source="phone"
                                        resource="users"
                                        label="resources.customers.fields.phone"
                                        fullWidth
                                    />
                                     <Separator/>
                                    {/* <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                source="size"
                                                resource="users"
                                                label="resources.customers.fields.size" 
                                                validation={requiredValidate}
                                                inputProps={{
                                                    step: 1,
                                                    min: 1
                                                  }}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                source="weight"
                                                resource="users"
                                                label="resources.customers.fields.weight" 
                                                fullWidth
                                                inputProps={{
                                                    step: 1,
                                                    min: 1
                                                  }}
                                            />
                                        </Box>
                                    </Box> */}
                                    {/* <Separator/>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="phone"
                                                resource="users"
                                                label="resources.customers.fields.phone" 
                                                validation={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                source="balance"
                                                resource="users"
                                                label="resources.customers.fields.balance" 
                                                fullWidth
                                                disabled
                                                inputProps={{
                                                    step: 1,
                                                    min: 1
                                                  }}
                                            />
                                        </Box>
                                    </Box>
                                    <Separator/> */}
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="users"
                        />
                    </form>
                </Card>
            )}
        />
    );
};


const requiredValidate = [required()];
const Separator = () => <Box pt="1em" />;
export default ClientEdit;
