import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    usePermissions,
} from 'react-admin';


import admins from '../admins';
import clients from '../clients';
import posts from '../posts';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import color from '@material-ui/core/colors/amber';
import { makeStyles } from '@material-ui/core/styles';
import { lightTheme } from './themes';

const useStyles = makeStyles(theme => ({
    menuItem: {
        active: { 
            fontWeight: 'bold', 
            color: theme.palette.primary.main,
            backgroundColor: 'red',
         },
    },
    icon: {
        color: theme.palette.secondary.main,
    },
}));

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const { permissions } = usePermissions();
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    });
    const classes = useStyles();
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            {' '}
            {/* only for admin */}
            {/* <SubMenu
                handleToggle={() => handleToggle('menuCustomers')}
                isOpen={state.menuCustomers}
                sidebarIsOpen={open}
                name="pos.menu.customers"
                icon={<admins.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/customers`}
                    primaryText={translate(`resources.customers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<admins.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/segments`}
                    primaryText={translate(`resources.segments.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LabelIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
        */}
            {/* only for admin */}
            {permissions === 'admin' && (
                <MenuItemLink
                    to={`/users`}
                    primaryText={translate(`resources.clients.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<clients.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {/* only for admin */}
            {permissions === 'admin' && (
                <MenuItemLink
                    className={classes.menuItem}
                    to={`/posts`}
                    primaryText={translate(`resources.posts.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<posts.icon className={classes.icon} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
