import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/browser";
import App from './App';
import './index.css';


Sentry.init({
    dsn: "https://b47de762c2904701b184b9a42452ea73@sentry.flexi-apps.com/31"
  });

ReactDOM.render(<App />, document.getElementById('root'));
