import React, { FC, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const FileSaver = require('file-saver');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        title: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
    })
);
const PackImagesGrid: FC<any> = ({ record, index, thumbnail = true }) => {
    const [selectedImg, setSelectedImg] = useState(0);

    const onImageChange = index => {
        console.log(index);
        setSelectedImg(index);
    };
    const downloadImg = () => {
        const img = downloadUrls[selectedImg];
        const imgUrl = `${process.env.REACT_APP_PUBLIC_URL}${img}`;

        FileSaver.saveAs(imgUrl, `image${selectedImg}.jpg`);
    };

    const classes = useStyles();

    // const imageUrls = record && record.imageUrls ? record.imageUrls : [];
    const downloadUrls =
        record && record.downloadUrls ? record.downloadUrls : [];

    return (
        <div className={classes.root}>
            {/* <GridList className={classes.gridList} cols={2.5}> */}
            <Carousel
                showThumbs={true}
                showIndicators={false}
                selectedItem={selectedImg}
                onChange={onImageChange}
            >
                {downloadUrls.map((image, i) => (
                    <>
                        <img
                            alt=""
                            src={`${process.env.REACT_APP_PUBLIC_URL}${image}`}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={downloadImg}
                        >
                            Télécharger
                        </Button>
                        {/* <button></button> */}
                    </>
                ))}
            </Carousel>
            {/* </GridList> */}
        </div>
    );
};

export default PackImagesGrid;
