export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
};

export const lightTheme = {
    palette: {
        primary: {
            light: '#FFFFFF',
            main: '#CD1468',
            dark: '#CD1468',
            contrastText: '#FFFFFF',
        },
        secondary: {
            light: '#FFFFFF',
            main: '#FFFFFF',
            dark: '#CD1468',
            contrastText: '#CD1468',
        },
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
};
