import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps, Post } from '../types';
import {
    ImageField,
} from 'react-admin';
import Chip from "@material-ui/core/Chip";
const useStyles = makeStyles({
    root: { width: 20, maxWidth: 25, maxHeight: 25 },
});

const PostThumbnailField: FC<any> = ({ record,index ,thumbnail=true}) => {

    // { record,index ,thumbnail=true}
    // const rootClasses=useStyles();
    // const propsClasses=makeStyles({root:style});

     const classes =useStyles();
    

     const imageUrl=record&&record.imageUrls[index]?record.imageUrls[index]:null;
     console.log(imageUrl,index)
    return imageUrl&&imageUrl.url? (
        (<img key={`img${index}`} src={`${process.env.REACT_APP_PUBLIC_URL}${imageUrl.url}`} className={thumbnail?classes.root:''} alt="" />)
    ) :(<Chip variant="outlined" size="small" label="Aucune Image" />);


};

// process.env.REACT_APP_PUBLIC_URL+
{/* <ImageField source="imageUrls" src="url"  /> */}
{/* <img src={imageUrl} className={thumbnail?classes.root:''} alt="" /> */}
export default PostThumbnailField;
